<template>
  <div class="products">
    <ul class="filter__items flex">
      <li class="filter__item">
        <b-form-select
          v-model="status"
          class="filter__item-select main__select"
          :options="listStatus"
          text-field="name"
          value-field="id"
          @change="statusChange()"
        />
        <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
      </li>
      <li class="filter__item">
        <button class="filter__item-btn" @click="dateShow = !dateShow">
          Дата создания
          <b-icon
            class="filter__item-btn-icon icon-right"
            style="color: #707070; margin-left: 10px"
            icon="calendar4-week"
          />
        </button>
        <div v-show="dateShow" class="sub-header__calendar">
          <vc-date-picker
            v-model="range"
            class="add__calendar"
            color="green"
            :rows="1"
            is-range
            @input="inputData()"
          />
          <button class="btn btn-primary mt-2" @click="inputDataClean('')">Очистить</button>
        </div>
      </li>
    </ul>
    <div v-if="dateShow" class="main-close" @click="dateShow = false" />
    <div v-if="products" id="chart">
      <apexchart type="line" height="350" :options="chartOptions" :series="series" />
    </div>
    <b-spinner v-else class="spinner" label="Large Spinner" />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapState } from "vuex";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      date: null,
      status: null,
      dateShow: false,
      range: {},
      min: 0,
      max: 12,
      minDate: 1,
      maxDate: 31,
    };
  },
  computed: {
    ...mapState(["products"]),
    series() {
      return [
        {
          name: "Заказы",
          data: this.range.start
            ? this.daysPoint.map((e) => e.count)
            : this.products.point_count.map((e) => e.count),
        },
        {
          name: "Маршруты",
          data: this.range.start
            ? this.daysOrder.map((e) => e.count)
            : this.products.order_count.map((e) => e.count),
        },
      ];
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          stroke: {
            curve: "smooth",
          },
          // locales: [{
          //   name: 'ru',
          // }]
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Cписок заказов",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#FFFFFF", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: this.range.start
            ? this.daysPoint.map((e) => e.day)
            : this.products.order_count.map((e) => e.month),
        },
      };
    },
    listStatus() {
      return [
        { id: null, name: "Выберите статус" },
        { id: "accepted", name: "Новый" },
        { id: "declined", name: "В резерве" },
        { id: "in_process", name: "В пути" },
        { id: "finished", name: "Завершен" }, //stopped
        { id: "send", name: "Отгружен" },
      ];
    },
    daysPoint() {
      return this.products.point_count
        .slice(this.min, this.max + 1)
        .map((e, index) =>
          e.days.map((day) => {
            return { ...day, month: e.month, index };
          })
        )
        .flat()
        .filter(
          (e) =>
            new Date("2022", e.index + this.min, e.day) >=
              new Date("2022", this.min, this.minDate) &&
            new Date("2022", this.max, this.maxDate) >= new Date("2022", e.index + this.min, e.day)
        );
    },
    daysOrder() {
      return this.products.order_count
        .slice(this.min, this.max + 1)
        .map((e, index) =>
          e.days.map((day) => {
            return { ...day, month: e.month, index };
          })
        )
        .flat()
        .filter(
          (e) =>
            new Date("2022", e.index + this.min, e.day) >=
              new Date("2022", this.min, this.minDate) &&
            new Date("2022", this.max, this.maxDate) >= new Date("2022", e.index + this.min, e.day)
        );
    },
  },
  async mounted() {
    if (this.$route.query.from != undefined && this.$route.query.to != undefined) {
      this.range.start = this.$route.query.from;
      this.range.end = this.$route.query.to;
    }
    this.setQueries()
    if(!this.products)
      this.search();
  },
  methods: {
    async search() {
      await this.$store.dispatch("getProducts", {
        from: this.range.start,
        to: this.range.end,
        status: this.status,
      });
    },
    statusChange() {
      const searchURL = new URL(window.location);
      var changed = false;

      if (this.$store.state.pointStatusFilter !== this.status) {

        if (this.status !== null)
          searchURL.searchParams.set('order_status', this.status);
        else
          searchURL.searchParams.delete('order_status');

        changed = true;
      }

      if (changed)
        window.history.pushState({}, '', searchURL)

      this.search();
    },
    inputData() {
      this.min = new Date(this.range.start).getMonth();
      this.max = new Date(this.range.end).getMonth();
      this.minDate = new Date(this.range.start).getDate();
      this.maxDate = new Date(this.range.end).getDate();
      this.range.start = new Date(this.range.start).toISOString().split("T")[0];
      this.range.end = new Date(this.range.end).toISOString().split("T")[0];
      this.dateShow = false;

      if (this.range.start !== undefined && this.range.end !== undefined)
      {
        const searchURL = new URL(window.location);

        searchURL.searchParams.set('from_date', this.range.start);
        searchURL.searchParams.set('to_date', this.range.end);

        window.history.pushState({}, '', searchURL)
      }
      
      this.search();
    },
    inputDataClean() {
      this.$store.commit("dateFilter", null);
      this.range = {}
       // убираем из ссылки
       const searchURL = new URL(window.location);
      searchURL.searchParams.delete('from_date');
      searchURL.searchParams.delete('to_date');
      window.history.pushState({}, '', searchURL)
      
      this.dateShow = false;
      
      this.search();
    },
    setQueries() {
      
      // если в ссылке имеются фильтры, применяем их к стейту
      if (this.$route.query.order_status !== undefined) {
        this.status = this.$route.query.order_status;
      }
      if (this.$route.query.from_date !== undefined && this.$route.query.to_date !== undefined) {
        this.range.start = this.$route.query.from_date;
        this.range.end = this.$route.query.from_date; 
      }

    }
  },
};
</script>

<style scoped>
.accounts {
  padding: 50px;
  background-color: white;
  margin: 20px 0;
}

#chart {
  margin: 35px auto;
  background-color: white;
}

.spinner {
  top: 50%;
}
.filter__items {
  justify-content: flex-start;
  flex-wrap: wrap;
}
.filter__item {
  position: relative;
  font-size: 14px;
  margin-top: 10px;
}

.add__calendar {
  position: static;
  background: #fff;
}
.filter__item:not(:last-child) {
  margin-right: 10px;
}
.sub-header__calendar {
  position: absolute;
  left: 22%;
  top: 45px;
  z-index: 4;
  background: #f8f8f8;
  padding: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
}

.filter__item-select {
  background: #ffffff;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  color: #707070;
  width: 180px;
}
.select-map {
  width: 180px;
}
.main__select-icon {
  top: 50%;
}
.filter__item-btn {
  height: 100%;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  padding: 10px 35px 10px 10px;
  color: #707070;
  text-align: start;
  position: relative;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

.filter__item-btn-icon {
  right: 3px;
}
</style>
